<template>
  <div>
    <h1>承認状態確認</h1>
    <v-card outlined class="mb-5">
      <v-expansion-panels flat v-model="panel">
        <v-expansion-panel>
          <!-- Header -->
          <v-expansion-panel-header>
            <span class="headline">検索条件</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col colspan="12">
                <DatePicker
                  class="mt-10"
                  v-model="date"
                  label="対象年月"
                  type="month"
                  outlined
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-card-actions class="mx-2">
        <v-spacer></v-spacer>
        <Btn color="info" icon="file-import" @click="getdata()">取得</Btn>
      </v-card-actions>
    </v-card>
    <!-- Table -->
    <v-card class="mt-5" v-if="items.length > 0">
      <v-card-title>
        <span class="text-h4">{{ title }}</span>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="簡易検索"
          outlined
          rounded
          dense
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :search="search"
        :item-key="itemKey"
        :items-per-page="-1"
        multi-sort
      >
        <template v-slot:item.actions="{ item }">
          <Btn color="info" text small @click="details(item)">確認</Btn>
        </template>
      </v-data-table>
    </v-card>
    <p v-else>承認が必要な情報はありません</p>
  </div>
</template>
<script>
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";
import { BUSYO_CODE as BusyoCode } from "@/defines";
const DATE = new Date();
export default {
  name: "ApprovalStatus",
  mixins: [Common, ShowDialogs, Api],
  props: {},
  data() {
    return {
      date: `${DATE.getFullYear()}-${DATE.getMonth() + 1}-${DATE.getDate()}`,
      selected: [],
      search: "",
      open: true,
      title: "テーブル名",
      itemKey: "name",
      headers: [],
      items: [],
      displayDate: "",
      panel: null
    };
  },
  computed: {
    isSelected() {
      return !!this.selected && this.selected.length > 0;
    }
  },
  methods: {
    async details(item) {
      console.log("details", item);
      const paymentDate = await this.getPaymentDate();
      const ymd = paymentDate.split("-");
      const param = {
        userincode: item.userIncode,
        targetdate: ymd[0] + "-" + ymd[1] + "-01",
        approvalStatus: "1",
        targetUsers: this.items,
        filter: {
          paymentDate: paymentDate
        }
      };
      let name = "Attendance";
      if (item.code1 === String(BusyoCode.WELFARE))
        name = "WelfareAttendanceDetails";

      this.$router.push({ name: name, params: param });
    },
    async getdata() {
      console.log("getdata");
      try {
        const params = {
          paymentDate: await this.getPaymentDate()
        };
        const encode = encodeURI(JSON.stringify(params));
        const data = await this.$get(
          this.Paths.approvalStatus,
          "query=" + encode
        );
        await this.setdata(data);
        this.displayDate = await this.getPaymentDate();
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      }
    },
    async setdata(data) {
      console.log("setdata");
      if (data && data.headers.length > 0) {
        this.headers = data.headers;
        this.headers.push({ text: "actions", value: "actions" });
        this.items = data.items;
        this.title = data.name;
        this.itemKey = data.key;
      }

      this.displayDate = "";
    },
    async getPaymentDate() {
      const ym = this.date.split("-");
      const dt = new Date(Number(ym[0]), Number(ym[1]), 1);
      dt.setDate(dt.getDate() - 1);

      const result =
        dt.getFullYear() +
        "-" +
        ("00" + Number(dt.getMonth() + 1)).slice(-2) +
        "-" +
        ("00" + dt.getDate()).slice(-2);

      return result;
    }
  },
  async created() {
    console.log("==created");
    this.date = this.$getCrossServiceDate();
    console.log(this.date);
    console.log("query", this.$route.query);
    if ("filter" in this.$route.query) {
      const param = this.$route.query.filter;
      if (typeof param !== "undefined" && Object.keys(param).length) {
        this.date = param.paymentDate;
      }
    }
    await this.getdata();
  },
  mounted() {
    console.log("==mounted");
  },
  beforeUpdate() {
    console.log("==beforeUpdate");
  },
  updated() {
    console.log("==updated");
  }
};
</script>
<style>
.v-data-table td {
  background: #f0f8ff;
}
.v-data-table tr:nth-child(odd) td {
  background: #fff;
}
.v-data-table tr:hover td {
  background-color: #eee;
}
</style>
